.home {
  .logo-txt {
    visibility: hidden;
  }
  .series {
    .cover {
      .volume {
        display: none;
      }
    }
  }
}