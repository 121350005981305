.home {
  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-sans-serif;
    font-size: rem(66px);
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 70px;
    @include media-breakpoint-up(lg) {
      font-size: rem(144px);
      margin-bottom: 120px;
      margin-top: 80px;
    }
    .kicker{
      font-family: $headings-font-family;
      font-size: rem(30px);
      font-weight: 700;
      @include media-breakpoint-up(lg) {
        font-size: rem(52px);
        margin-bottom: 40px;
      }
    }
    .prename {
      display: flex;
      justify-content: center;
      font-family: $headings-font-family;
      font-size: rem(50px);
      //font-style: italic; --> jetzt beim Mandanten
      font-weight: 700;
      margin-bottom: -15px;
      @include media-breakpoint-up(lg) {
        font-size: rem(104px);
        margin-bottom: -35px;
      }
    }
  }
}

/*Text-Modul*/
h1{
  display: flex;
  justify-content: center;
}
h1,
h2 {
  position: relative;
  .kicker {
    display: block;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      font-size: rem(18px);
    }
  }
  .headline {
    display: block;
    font-family: $headings-font-family;
    font-size: rem(41px);
    font-weight: 300;
    font-style: italic;
    margin-bottom: rem(20px);
    position: relative;
    @include media-breakpoint-up(lg) {
      font-size: rem(62px);
    }
    &:before {
      content: " ";
      background: $primary;
      width: 80px;
      height: 15px;
      display: block;
      position: absolute;
      z-index: -1;
      bottom: 5px;
      left: -20px;
      -moz-transition: all 0.6s ease-in-out;
      -o-transition: all 0.6s ease-in-out;
      -webkit-transition: all 0.6s ease-in-out;
      -ms-transition: all 0.6s ease-in-out;
      transition: all 0.6s ease-in-out;
      @include media-breakpoint-up(lg) {
        width: 110px;
        height: 26px;
      }
    }
  }
  .subline {
    display: block;
    font-family: $headings-font-family;
    font-size: rem(26px);
    font-style: italic;
    margin-bottom: rem(40px);
    opacity: 0.74;
    @include media-breakpoint-up(lg) {
      font-size: rem(32px);
    }
  }
}

.tab-content {
  h2 {
    font-size: rem(28px);
    margin-bottom: 15px;
  }
  h3 {
    font-size: rem(24px);
    margin-bottom: 10px;
  }
}