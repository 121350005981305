//
// lists
//

.list {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;
  }
  &.standardlist {
    column-count: 2;
    column-gap: 40px;
    -moz-column-gap: 40px;
    -webkit-column-gap: 40px;
    padding-left: 15px;
    text-indent: -30px;
    padding-left: 30px;
    li {
      @include icon-base($icon-mask: true) {
        @include svg-icon("../../../global/img/icon_listicon.svg", 10px, 10px, $primary);
        top: 0;
        margin-right: 20px;
      }
    }
  }
  &.linklist {
   color: inherit;
    li a {
      text-decoration: none;
      color: inherit;
      @include icon-base($icon-mask: true) {
        @include svg-icon("../../../global/img/icon_arrow-right.svg", 9px, 28px, $primary);
        margin-right: 20px;
        top: -2px;
      }
      &:hover {
        &:before {
          animation: arrow-move-right;
          animation-duration: 0.3s;
          animation-fill-mode:forwards;
          animation-iteration-count: 1;
          //animation-timing-function: cubic-bezier(.2,.97,.35,.99);
        }
      }
    }
  }
  &.downloads {
    li a {
      text-decoration: none;
      color: inherit;
      @include icon-base($icon-mask: true) {
        @include svg-icon("../../../global/img/icon_download.svg", 18px, 13px, $primary);
        margin-right: 15px;
        top: 2px;
      }
    }
  }
}

div[class^="offset"],
div[class*=" offset"] {
  .list {
    &.standardlist {
      column-count: 1;
      column-gap: 0;
      -moz-column-gap: 0;
      -webkit-column-gap: 0;
    }
  }
}

//Redactor
.text ul {
  list-style: none;
  padding: 0;
  column-count: 1;
  column-gap: 0;
  -moz-column-gap: 0;
  -webkit-column-gap: 0;
  text-indent: -30px;
  padding-left: 30px;
  li {
    @include icon-base($icon-mask: true) {
      @include svg-icon("../../../global/img/icon_listicon.svg", 10px, 10px, $primary);
      top: 0;
      margin-right: 20px;
    }
  }
}
