footer{
  color: #fff;
  display: block;
  font-family: $font-family-sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 80px;
  z-index: 100;
  @include media-breakpoint-up(md) {
    bottom: 80px;
    padding: 0;
    left: 80px;
  }
  .footer-navi {
    ul {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
      li {
        display: inline;
        margin: 0 0 20px 0;
        @include media-breakpoint-up(md) {
          margin: 0 40px 0 0;
        }
        a {
          color: $txt-color;
          text-decoration: none;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}