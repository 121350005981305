.header{
  @include media-breakpoint-up(md) {
    padding: 0 80px;
    position: absolute;
    top: 80px;
  }
}
.logo-wrapper {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;
  .logo-img {
    @include icon-base($icon-mask: true) {
      @include svg-icon("../img/logo_img.svg", 33px, 40px, $txt-color);
    }
  }
  .logo-txt {
    @include icon-base($icon-mask: true) {
      @include svg-icon("../img/logo_txt.svg", 86px, 40px, $txt-color);
    }
  }
  &:hover {
    .logo-img,
    .logo-txt{
      &:before {
        background-color: $secondary;
      }
    }
  }
  @include media-breakpoint-up(md) {
    gap: 25px;
    .logo-img {
      @include icon-base($icon-mask: true) {
        @include svg-icon("../img/logo_img.svg", 41px, 50px, $txt-color);
      }
    }
    .logo-txt {
      @include icon-base($icon-mask: true) {
        @include svg-icon("../img/logo_txt.svg", 129px, 60px, $txt-color);
      }
    }

  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

@include media-breakpoint-down(md) {
  .header-wrapper {
    height: 80px;
  }
}

.header-navi {
  font-family: $headings-font-family;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: $txt-color;
  display: flex;
  gap: 60px;
  align-items: center;
  right: 20px;
  z-index: 100;
  ul {
    list-style: none;
    padding: 0;
    li {
      display: inline;
      margin-right: 40px;
      float: left;
      a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.6s all;
      }
      &:hover,
      &:focus-within,
      &.active
       {
        transition: 0.6s all;
        > a {
          color: $primary;
        }
      }
      /*Subnavi*/
      ul {
        border-bottom: 3px solid $primary;
        border-top: 3px solid $primary;
        margin-top: 20px;
        padding: 7px 0;
        display: none;
        flex-direction: column;
        li {
          a {
            display: block;
            font-size: 18px;
            line-height: 20px;
            padding: 5px 0;
          }
        }
      }
      &:hover,
      &:focus-within {
        ul {
          display: flex;
        }
      }
    }
  }
}
.icon-instagram {
  display: flex;
  @include icon-base($icon-mask: true) {
    @include svg-icon("../../../global/img/icon_instagram.svg", 40px, 40px, $txt-color);
  }
}


/*Mobile Menu*/
.mobile-menu-button {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.6s all;
  &:before {
    content: "Menu";
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
  }
  .menu-button {
    display: flex;
    @include icon-base($icon-mask: true) {
      @include svg-icon("../../../global/img/icon_menu.svg", 36px, 38px, $txt-color);
    }
  }
  &:hover{
    color: $secondary;
    .menu-button {
      @include icon-base($icon-mask: true) {
        @include svg-icon("../../../global/img/icon_menu.svg", 36px, 38px, $secondary);
      }
    }
  }
}
.mobile-menu-overlay {
  background: $body-bg;
  position: fixed;
  //z-index: 110;
  //display: none;
  //overflow-y: scroll;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //top: 0;
  .modal-header {
    border: 0;
    padding: 0;
  }
  .modal-body {
    padding: 0;
  }
}
.close-menu {
  z-index: 150;
  border: 0;
  background-color: transparent;
  padding: 0;
  @include icon-base($icon-mask: true) {
    @include svg-icon("../../../global/img/icon_close.svg", 40px, 40px, $secondary);
  }
  position: relative;
  top: 20px;
  right: 15px;
  @include media-breakpoint-up(md) {
    top: 15px;
  }
}
.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  position: relative;
  top: 70px;
  li{
    margin-bottom: 30px;
    position: relative;
    a {
      color: $txt-color;
      font-family: $headings-font-family;
      font-size: rem(32px);
      font-style: italic;
      font-weight: 900;
      line-height: 1.5;
      text-decoration: none;
      &:before {
        content: " ";
        background: $primary;
        width: 80px;
        height: 15px;
        display: block;
        position: absolute;
        z-index: -1;
        bottom: 5px;
        left: -20px;
        //fade background rectangel
        opacity: 0;
        -moz-transition: all 0.6s ease-in-out;
        -o-transition: all 0.6s ease-in-out;
        -webkit-transition: all 0.6s ease-in-out;
        -ms-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
      }
    }
    &.active,
    &:hover {
      a {
        &:before {
          //animation
          opacity: 1;
        }
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 23px;
    li {
      margin-bottom: 0;
      a {
        display: block;
        text-transform: none;
        font-family: $headings-font-family;
        font-size: rem(26px);
        font-weight: 700;
        margin: 8px 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .mobile-menu {
    top: 250px;
    li {
      margin-bottom: 50px;
      a {
        font-size: rem(62px);
        &:before {
          width: 110px;
          height: 26px;
        }
      }
    }
  }
}

/*Meta-Seite*/
.headerimage {
  height: 448px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
  &:before {
    background: radial-gradient(circle, rgba(0,167,203,0) 0%, rgba(0,0,0,0.9) 100%);
    display: block;
    width: 100%;
    height: 100%;
    content: " ";
  }
  img {
    min-height: 100%;
  }
}
.overlay-headline {
  font-family: $headings-font-family;
  font-size: 32px;
  font-weight: 900;
  position: absolute;
  text-transform: uppercase;
  top: auto;
  bottom: 55px;
  @include media-breakpoint-up(md) {
    font-size: 62px;
    top: 65%;
    bottom: auto;
  }
  &:after {
    border-bottom: 7px solid $primary;
    content: " ";
    position: absolute;
    left: -9890px;
    height: 5px;
    display: inline-block;
    width: 10000px;
    bottom: -25px;
    @include media-breakpoint-up(md) {
      height: 7px;
      left: -9825px;
    }
  }
}