body {
  position: relative;
}

/* Section */
.section {
  margin-bottom: 75px;
  @include media-breakpoint-up(md) {
    margin-bottom: 150px;
  }
}

a {
  color: $primary;
  text-decoration: none;
  transition: 0.6s all;
  &:hover {
    color: $secondary;
  }
}

.modal-fullscreen {
  width: 100%;
  overflow: hidden!important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

img[data-sizes="auto"] { display: block; width: 100%; }

.spotify-wrapper {
  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }
}

.copyright {
  color: $light;
  font-size: rem(14px);
  position: absolute;
  transform: rotate(-90deg);
  bottom: -10px;
  left: calc(100% - 25px);
  transform-origin: 0% 0% 0;
  width: 100%;
}

//Icon
.icon {
  &:before {
    transition: 0.6s all;
  }
  &:hover {
    color: $primary;
    &:before {
      background-color: $secondary;
    }
  }
}

//Links
.morelink {
  color: $txt-color;
  font-weight: 600;
  text-decoration: none;
}

//DefinitionList
dt {
  font-weight: 600;
}
