.nav-tabs{
  .nav-link {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 80px;
    text-transform: uppercase;
    &:hover,
    &.active {
      color: $txt-color;
      border-color: $primary;
    }
  }
}
.tab-pane {
  font-size: rem(18px);
  padding-top: 40px;
}
.bookinfo {
  margin-top: 50px;
}

.intro {
  font-weight: 600;
}