.events {
  padding-left: 0!important;
  @include media-breakpoint-up(sm) {
    padding-left: calc(50% - 340px) !important;
  }
  @include media-breakpoint-up(md) {
    padding-left: calc(50% - 340px) !important;
  }
  @include media-breakpoint-up(lg) {
    padding-left: calc(50% - 460px) !important;
  }
  @include media-breakpoint-up(xl) {
    padding-left: calc(50% - 640px) !important;
  }
  .swiper-wrapper {
    margin-bottom: 40px;
  }
  .swiper-slide {
    height: auto;
  }
}
.events-item {
  background-color: $primary;
  background: linear-gradient(314.22deg, $grad_light 0%, $grad_dark 100%);
  border-radius: 3px;
  height: 100%;
  .events-item-content {
    color: $txt-color;
    display: block;
    text-decoration: none;
    padding: 50px 40px 100px 40px;
  }
}
.events-date {
  font-family: $font-family-sans-serif;
  font-size: rem(18px);
  line-height: rem(30px);
  padding-bottom: 40px;
}
.events-headline {
  font-family: $headings-font-family;
  font-size: rem(42px);
  font-style: italic;
  line-height: rem(51px);
  padding-bottom: 50px;
}
.events-text {
  font-family: $font-family-sans-serif;
  font-size: rem(20px);
  line-height: rem(28px);
}


/* events-List */
.events-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  .events-item {
    flex-basis: 400px;
    flex-grow: 1;
    height: auto;
  }
}