.swiper-slide {
  width: auto;
}

/* Pagination */
.swiper-pagination-bullet {
  background-color: $light!important;
}
.swiper-pagination-bullet-active {
  background-color: $secondary!important;
}
.swiper-navigation{
  margin-top: 20px;
}
.swiper-pagination {
  bottom: 0;
  margin: 35px 30px 0 30px;
  position: relative;
}
.swiper-pag-btn {
  content: '';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid $primary;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.swiper-prev {
  &:before {
    @include svg-icon("../../../global/img/icon_arrow-left.svg", 12px, 18px, $light );
    content: " ";
    display: inline-block;
    &.swiper-button-disabled {
      @include svg-icon("../../../global/img/icon_arrow-left.svg", 12px, 18px, $light );
      opacity: 0.2;
    }
  }
}
.swiper-next {
  &:before {
    content: " ";
    display: inline-block;
    @include svg-icon("../../../global/img/icon_arrow-right.svg", 12px, 18px, $light);

    &.swiper-button-disabled {
      @include svg-icon("../../../global/img/icon_arrow-right.svg", 12px, 18px, $light);
      opacity: 0.2;
    }
  }
}