//.series {
//  display: flex;
//  justify-content: space-between;
//  gap: 50px;
//  .cover {
//    max-width: 330px;
//    width: 330px;
//  }
//}
.series{
  a {
    color: $txt-color;
  }
  .cover {
    color: $txt-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    .volume {
      margin-top: 20px;
      transition: 0.6s all;
    }
    &:hover {
      color: $secondary;
    }
  }
}
