@charset 'UTF-8';

// 1. Variables
@import
'assets_dev/global/scss/vars/functions',
'vars/colors',
'vars/vars',
'vars/fonts',
'assets_dev/global/scss/vars/vars',
'assets_dev/global/scss/vars/mixins';


// 2. Libs/Vendors
@import
'~bootstrap/scss/bootstrap',
'~swiper/swiper.scss',
'~swiper/modules/navigation/navigation',
'~swiper/modules/pagination/pagination',
'~magnific-popup/src/css/main';

// 3. Base stuff
@import
'assets_dev/global/scss/base/buttons',
'assets_dev/global/scss/base/global',
'assets_dev/global/scss/base/headlines';


// 4. Layout-related sections
@import
'assets_dev/global/scss/layout/footer',
'assets_dev/global/scss/layout/header';
//'assets/global/scss/layout/klaro';

// 5. Modules
@import
'assets_dev/global/scss/modules/lists',
'../../../global/scss/modules/swiper',
'assets_dev/global/scss/modules/tab_content',
'assets_dev/global/scss/modules/series',
'../../../global/scss/modules/events';


// 6. Page-specific styles
@import
'assets_dev/global/scss/pages/home',
'assets_dev/global/scss/pages/article';


//Custom Styles
.home {
  h1{
    font-style: normal;
    .prename {
      font-style: italic;
    }
  }
}
h1 {
  font-style: italic;
}


/*# sourceMappingURL=main.css.map */
